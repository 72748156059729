<template lang="pug">
  table.container-fluid
    thead
      tr
        th.sync
          .column-name {{ $t("shop_matching.sync") }}
        th.ota
          .column-name {{ $t("ota_accounts.ota") }}
        th.actions
    tbody
      AccountListItem(
        v-for="account in accounts"
        :key="account.id"
        :account="account"
        :action-title="actionTitle"
        :progress-bar-label="progressBarLabel"
        :ota-name="translatedOtaNamesMapping[account.ota.id]"
        :progress-data="progressData[account.id]"
        @action-click="$emit('action-click', $event)"
      )
</template>

<script>
  // misc
  import { mapGetters } from "vuex"

  export default {
    components: {
      AccountListItem: () => import("./Item")
    },

    props: {
      progressData: {
        type: Object,
        default: () => {}
      },
      actionTitle: {
        type: String,
        required: true
      },
      progressBarLabel: {
        type: String,
        required: true
      },
      accounts: {
        type: Array,
        default: () => []
      }
    },

    computed: {
      ...mapGetters(["translatedOtaNamesMapping"])
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/tables/custom.sass"

  thead
    th
      +custom-table-th

      &.ota
        width: 10%
      &.sync
        width: 7%
        text-align: left

      &.actions
        width: 80%
</style>
